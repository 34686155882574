import React, {useCallback, useEffect, useState} from "react";
import {LicenseModalPropsTypes} from "./LicenseModalTypes";
import {Button, Form, message, Modal, Spin} from "antd";
import doTask from "../../../../../../../logic/UiManager";
import {
    createDemoDataOnCrm,
    createLicenses,
    editLicenses,
    updateEndTimeLicense
} from "../../../../../../../logic/LicensesManager";
import ErrorBoundary from "../../../../../../errorBoundary/ErrorBoundary";
import LicenseModalForm from "./form/LicenseModalForm";
import useAsyncEffect from "use-async-effect";
import {getLicenseTemplatesList} from "../../../../../../../logic/LicensesTemplatesManager";
import {LicensesTemplate} from "../licensesTemplates/LicensesTemplateTypes";

const LicenseModal: React.FC<LicenseModalPropsTypes> = (props) => {
    const [form] = Form.useForm()
    const [loading,setLoading] = useState<boolean>(false)
    const [licenseTemplate,setLicenseTemplate] = useState<LicensesTemplate[]>([])

    useAsyncEffect(async () => {
        if (props.visible) {
            await handleGetTemplatesList()
        }
    },[props.visible])

    useEffect(() => {
        if (props.editableLicense) {
            form.setFieldsValue({
                ...props.editableLicense,
            })
        }
    },[props.editableLicense])

    const handleCancelModal = useCallback(() => {
        form.resetFields()
        setLicenseTemplate([])
        props.onCloseModal()
    },[props.visible])


    const callCreateOrEditLicense = async (licenseId: number | undefined, body: object) => {
        const hide = message.loading(`${licenseId ? 'Редактирование' : 'Создание'} лицензии`, 1000)
        await doTask(async () => {
            const res = licenseId ? await editLicenses(licenseId, body) : await createLicenses(body)
            if (res) {
                message.success(`Лицензия успешно ${licenseId ? 'отредактирована' : 'добавлена'}`)
            }
            if (res?.data?.id) {
                await updateEndTimeLicense(res.data.id)
               /* const resCreateDemoData = await createDemoDataOnCrm(res.data.id)
                if (resCreateDemoData) {
                    message.success(`CRM компании успешно наполнилась тестовыми данными`)
                } else {
                    message.error(`Ошибка наполнения CRM компании тестовыми данными`)
                }*/
            }
        }).finally(() => {
            hide()
            handleCancelModal()
        })
    }

    const handleCreateOrEditLicense = useCallback(() => {
        form.validateFields().then(async (values) => {
            setLoading(true)
            const body = {
                ...values,
                demo_data: values?.demo_data ? JSON.parse(values?.demo_data) : false,
                company: props.partnerId
            }
            await callCreateOrEditLicense(props.editableLicense?.id,body)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    },[props.partnerId])

    const handleGetTemplatesList = useCallback(async () => {
        await doTask(async () => {
            setLoading(true)
            const res = await getLicenseTemplatesList()
            if (res?.data) {
                setLicenseTemplate(res.data)
            }
        }).finally(() => {
            setLoading(false)
        })
    },[props.visible, loading])

    return (
        <>
            <Modal
                destroyOnClose={true}
                title={props.editableLicense ? 'Редактировать лицензию' : 'Добавить лицензию'}
                open={props.visible}
                onCancel={handleCancelModal}
                centered={true}
                closable={true}
                footer={[
                    <Button
                        onClick={() => {
                            handleCancelModal()
                        }}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        disabled={loading}
                        onClick={async () => {
                            await handleCreateOrEditLicense()
                        }}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <Spin spinning={loading}>
                    <ErrorBoundary>
                        <LicenseModalForm
                            form={form}
                            licenseTemplates={licenseTemplate}
                        />
                    </ErrorBoundary>
                </Spin>
            </Modal>
        </>
    )
}


export default LicenseModal
